import { Controller } from "@hotwired/stimulus";
import { fill, escape as escapeText, raw } from "@domchristie/composite";

export default class OptimisticFormController extends Controller {
  static targets = ["actions", "mid"];

  async performActions() {
    const htmlContent = fill(this.actionsTarget, {
      controller: this,
      params: this.params,
    });

    this.element.insertAdjacentHTML("beforeend", `
      ${htmlContent}
      <turbo-stream action="scroll_into_view" target="${this.midTarget.value}"></turbo-stream>
    `);
    this.midTarget.value = crypto.randomUUID();
    this.element.reset();
  }

  get params() {
    return Object.fromEntries(new FormData(this.element));
  }

  simpleFormat(text) {
    let escapedText = escapeText(text);
    escapedText = escapedText
      .replace(/\r\n?/g, "\n")
      .replace(/\n\n+/g, "</p>\n\n<p>")
      .replace(/([^\n]\n)(?=[^\n])/g, "$1<br/>");
    return raw(`<p>${escapedText}</p>`);
  }
}
