import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contact"];

  toggleContactsMenu() {
    document.body.classList.toggle("active-chat-contacts");
  }

  selectConversation(event) {
    setTimeout(() => {
      document.body.classList.remove("active-chat-contacts");
    }, 100);

    this.#clearActive();
    event.currentTarget.classList.add("active");
  }

  #clearActive() {
    for (const contact of this.contactTargets) {
      contact.classList.remove("active");
    }
  }
}
