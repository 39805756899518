import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  connect() {
    const [observe, unobserve] = useIntersection(this);
    this.observe = observe;
    this.unobserve = unobserve;
  }

  appear() {
    this.unobserve()
    this.maskAsRead()
  }

  maskAsRead() {
    fetch(this.readPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).catch(() => {
      this.observe()
    })
  }

  get readPath() {
    return this.element.dataset.readPath;
  }
}
