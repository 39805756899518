import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input/build/js/intlTelInputWithUtils.min.js";
import { ca, tr } from "intl-tel-input/i18n"

const ERROR_MESSAGES = {
  en: {
    required: "required",
    messages: ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"]
  },
  tr: {
    required: "Gerekli",
    messages: ["Geçersiz numara", "Geçersiz ülke kodu", "Çok kısa", "Çok uzun", "Geçersiz numara"]
  }
}

export default class extends Controller {
  static targets = ["input", "error"];

  static values = {
    currentNumber: String,
    hiddenInputs: Object,
  };

  connect() {
    this.errorTarget.classList.add("d-none");

    this.intl = intlTelInput(this.inputTarget, {
      i18n: tr,
      hiddenInput: () => this.hiddenInputsValue,
      nationalMode: true,
      placeholderNumberType: "MOBILE",
      separateDialCode: true,
      strictMode: true,
      countryOrder: ["tr"],
      preferredCountries: ["tr"],
      initialCountry: "auto",
      geoIpLookup: (success, failure) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => success(data.country_code))
          .catch(() => failure());
      },
    });

    if (this.currentNumberValue !== "") {
      this.intl.setNumber(this.currentNumberValue);
    }
  }

  validate() {
    if (this.number === "" || this.intl.isValidNumber()) {
      this.handleError("");
    } else {
      this.handleError(
        ERROR_MESSAGES[this.currentLocale].messages[this.intl.getValidationError()] || ERROR_MESSAGES[this.currentLocale].messages[0]
      )
    }
  }

  setDisabled(status) {
    this.intl.setDisabled(status);
  }

  handleError(message) {
    if (message === "") {
      this.errorTarget.classList.add("d-none");
      return;
    }

    this.errorTarget.innerHTML = message;
    this.errorTarget.classList.remove("d-none");
  }

  disconnect() {
    this.intl.destroy();
    super.disconnect();
  }

  get number() {
    return this.intl.getNumber();
  }

  get currentLocale() {
    return "tr"
  }
}
