import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import { get } from "@rails/request.js"

export default class extends Controller {
  connect() {
    const [observe, unobserve] = useIntersection(this, {
      rootMargin: "100px",
    });

    this.observe = observe
    this.unobserve = unobserve
  }

  appear() {
    this.element.classList.remove("invisible");
    this.loadMore(this.nextUrl);
    this.unobserve()
  }

  async loadMore(url) {
    get(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  get nextUrl() {
    return this.data.get("nextUrl");
  }
}
