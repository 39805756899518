import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phone", "email"];
  static values = { currentUsernameType: String };
  static outlets = ["phone-input"];

  connect() {
    this.#initializeFormType();
    this.#setInputsVisibility();

  }

  select({ target: { value } }) {
    this.currentUsernameTypeValue = value;
    this.#setInputsVisibility();
  }

  #initializeFormType() {
    const targetEl = this.currentUsernameTypeValue === "phone_number" ? this.phoneTarget : this.emailTarget;
    targetEl.checked = true;
    targetEl.click();
  }

  #setInputsVisibility() {
    this.#updateElementVisibility(this.phoneTarget, this.#isPhoneNumber);
    this.#updateElementVisibility(this.emailTarget, !this.#isPhoneNumber);

    if (this.#isPhoneNumber) {
      this.phoneInputOutlet.setDisabled(false);
    }
  }

  #updateElementVisibility(element, visible) {
    const classListMethod = visible ? "remove" : "add";

    for (const el of [element, element.closest(".form-group")]) {
      el.classList[classListMethod]("d-none");
    }
    element.disabled = !visible;
  }

  get #isPhoneNumber() {
    return this.currentUsernameTypeValue === "phone_number";
  }
}
