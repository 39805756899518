import { Controller } from "@hotwired/stimulus";

export default class CharacterCounter extends Controller {
  static targets = ["input", "counter"];
  static values = {
    countdown: Number,
    mode: { type: String, default: "char" },
    limiter: { type: Boolean, default: false },
  };

  initialize() {
    this.update = this.update.bind(this);
  }

  connect() {
    this.update();
    this.inputTarget.addEventListener("input", this.update);
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update);
  }

  update() {
    if (this.limiterValue) {
      this.inputTarget.value = this.inputTarget.value.slice(0, this.maxLength);
    }

    this.counterTarget.innerHTML = this.counter.toString();
  }

  get counter() {
    let count = this.value.length;

    if (this.hasCountdownValue) {
      count = Math.max(this.countdownValue - count, 0);
    }

    return count;
  }

  get maxLength() {
    if (this.hasCountdownValue) {
      return this.countdownValue;
    }

    return this.inputTarget.maxLength;
  }

  get value() {
    const raw = this.inputTarget.value;

    if (raw.length === 0) return ""

    switch (this.modeValue) {
        case "word":
          return raw.split(/\s+/);
        case "line":
          return raw.split(/\r\n|\r|\n/);
        default:
          return raw;
      }
    }
}
