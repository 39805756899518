import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["add_item", "template"];

  add_association(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /_index_/g,
      this.#incrementChildIndex(),
    );
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);
    this.#dispatchEvent("nested_form:add-field");
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
    this.#dispatchEvent("nested_form:remove-field");
  }

  #incrementChildIndex() {
    const newIndex = Number(this.templateTarget.dataset.lastChildIndex) + 1;
    this.templateTarget.dataset.lastChildIndex = newIndex;
    return newIndex;
  }

  #dispatchEvent(event_key) {
    document.dispatchEvent(new CustomEvent(event_key));
  }
}
