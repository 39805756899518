import LocalTime from "local-time";
LocalTime.config.useFormat24 = true;

LocalTime.config.i18n.tr = {
  date: {
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    abbrDayNames: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    abbrMonthNames: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    yesterday: "dün",
    today: "bugün",
    tomorrow: "yarın",
    on: "{date} tarihinde",
    formats: {
      default: "%e %b %Y",
      thisYear: "%e %b",
    },
  },
  time: {
    am: "öö",
    pm: "ös",
    singular: "bir {time}",
    singularAn: "bir {time}",
    elapsed: "{time} önce",
    second: "saniye",
    seconds: "saniye",
    minute: "dakika",
    minutes: "dakika",
    hour: "saat",
    hours: "saat",
    formats: {
      default: "%k:%M%P",
      default_24h: "%H:%M",
    },
  },
  datetime: {
    at: "{date} tarihinde, {time} saatinde",
    formats: {
      default: "%e %B %Y, %k:%M%P %Z",
      default_24h: "%e %B %Y, %H:%M %Z",
    },
  },
};

LocalTime.config.locale = "tr";
LocalTime.start();
