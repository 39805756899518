import { application } from "./application";
application.debug = true;

import CharacterCounterController from "./character_counter_controller.js";
import FileUploadInputController from "./file_upload_input_controller.js";
import InfiniteScrollController from "./infinite_scroll_controller.js";
import PhoneInputController from "./phone_input_controller.js";
import RegistrationFormController from "./registration_form_controller.js";
import ResetFormController from "./reset_form_controller.js"
import OptimisticFormController from "./optimistic_form_controller.js";
import MessageReadController from "./message_read_controller.js";
import ChatController from "./chat_controller.js";
import MenuController from "./menu_controller.js";
import NestedFormController from "./nested_form_controller.js";

application.register("character-counter", CharacterCounterController);
application.register("file-upload-input", FileUploadInputController);
application.register("infinite-scroll", InfiniteScrollController);
application.register("phone-input", PhoneInputController);
application.register("registration-form", RegistrationFormController);
application.register("reset-form", ResetFormController);
application.register("optimistic-form", OptimisticFormController);
application.register("message-read", MessageReadController);
application.register("chat", ChatController);
application.register("menu", MenuController);
application.register("nested-form", NestedFormController);
